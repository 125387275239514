import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { UserModel } from '../services/models/user.model';
import { Services } from '../services/web-services';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UserDialogComponent } from '../user-dialog/user-dialog.component';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.css']
})
export class UsersComponent implements OnInit {
  displayedColumns: string[] ;//= ['select','id', 'name', 'progress', 'color'];
  dataSource: MatTableDataSource<UserModel>;
  selection = new SelectionModel<UserModel>(true, []);
  users: Array<UserModel> = []

  @ViewChild(MatSort, {static: true}) sort: MatSort;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  constructor(private _services: Services,
    public dialog:MatDialog) { }

  ngOnInit() {
    this.dataSource = new MatTableDataSource()
    this.users = []
    this._services.getAllUsers().subscribe(res=>{
      this.displayedColumns = res["columnNames"]
      this.createUser(res["users"])
    },error=>{
      console.log(error)
    });
  }

  createUser(data){
    data.forEach(element => {
      let u = new UserModel;
      u.ID = element["usr_ID"]
      u.Deleted = element["Deleted"]
      u.createdAt = element["usr_CreatedAt"]
      u.description = element["usr_Description"]
      u.enabled = element["usr_Enabled"]
      u.expiration = element["usr_Expiration"]
      u.loginName = element["usr_LoginName"]
      u.name = element["usr_Name"]
      u.password = element["usr_Password"]
      u.passwordChanged = element["usr_PasswordChanged"]

      this.users.push(u);
    });
    this.assignData();
  }

  assignData(){
    if(this.users.length>0){
      this.dataSource = new MatTableDataSource(this.users)
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
    }
    this.users.forEach(element => {
      if(element.enabled){
        this.selection.select(element)
      }
    });
   }

  createNewUser(){
    const dialogRef = this.dialog.open(UserDialogComponent, {
      width: '20%',
      height: '90%',
      data: {row: event}
    });

    dialogRef.afterClosed().subscribe(result => {
      this.ngOnInit();
    });
  }

  onCheckSource(event,row:UserModel){
    if(row.enabled){
      
    }
    
    let auxSelect = this.selection.isSelected(row) ? 'deselect' : 'select';
    if(auxSelect == "select"){
      this.selection.select(row)
      this._services.putActivateUser(row.loginName).subscribe(res=>{
      },error=>{
        console.log(error);
      })
    }else{
      this.selection.deselect(row)

      this._services.putDisableUser(row.loginName).subscribe(res=>{
      },error=>{
        console.log(error)
      })
    }
  }

}
