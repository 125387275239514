import { Component, OnInit, AfterViewInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ReadTagDetailsComponent } from '../read-tag-details/read-tag-details.component';
import { ReadTagModel } from '../services/models/readTag.model';
import { Services } from '../services/web-services';

const MINUTES_UNITL_AUTO_LOGOUT = 180 // in mins
const CHECK_INTERVAL = 30000 // in ms

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit, AfterViewInit {
  language:any;
  public alerts = []
  
  constructor(private router:Router,
    public dialog: MatDialog,
    private _services:Services,
    public translate: TranslateService) { }
    
    ngOnInit() {
      this.language = localStorage.getItem("language")
      this.translate.use(this.language)
      let url = window.location.pathname;
      url = url.replace("/","");
      this.initInterval();
      // this.getAlerts();  
    }

    ngAfterViewInit(): void {
      this.getCount()
    }
    
  getCount(){
    this._services.getCountAlertsSession().subscribe(res=>{
      this.alerts = res["records"]
      this.createReadsTags(res["records"]);
    });

    setTimeout(()=>{
        this.getCount()
    },10000)
  }

  showDetails(){
    const dialogRef = this.dialog.open(ReadTagDetailsComponent, {
      disableClose: true,
      width: '95%',
      height: 'auto',
      data: this.alerts
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }

  click(event){
    // if(event["srcElement"]["id"] == "logOut"){
    //   localStorage.clear();
    //   sessionStorage.clear();
    //   this.router.navigate(['./login'])
    // }else{
    //   this.router.navigate([event["srcElement"]["id"]])
    // }

    if(event.currentTarget.id == "logOut"){
      localStorage.clear();
      sessionStorage.clear();
      this.router.navigate(['./login'])
    }else{
      this.router.navigate(['./'+event.currentTarget.id])
    }
  }

  initInterval() {
    setInterval(() => {
      this.check();
    }, CHECK_INTERVAL);
  }

  check() {
    let token = JSON.parse(sessionStorage.getItem('token'));
    let now = Date.now();
    let timeleft;
    if(token!= undefined){

      timeleft = token.date + MINUTES_UNITL_AUTO_LOGOUT * 60 * 1000;
  
      const diff = token.date - now;
      let aux = new Date(diff)

      //console.log("Dif: ",aux.getMinutes())
      const isTimeout = diff < 0;
      //console.log("isTimeout: ",isTimeout)

      if (isTimeout) {
        localStorage.clear();
        sessionStorage.clear();
        this.router.navigate(['./login'])
      }
    }
  }

  createReadsTags(data){
    let auxReads= []
    data.forEach(element => {
      let r = new ReadTagModel;
        r.Alert = element['status']
        r.Brand = element['brand']
        r.Camera = element['camera']
        r.Country = element['country']
        r.IdAlert = element['idalert']
        r.Device = element['scr_name']
        r.Location = element['alocation']
        r.Messages = element['messages']
        r.Model = element['model']
        r.Overview = element['overview']
        r.Patch = element['patch']
        r.Timestamp = element['timestamp']
        r.Typelecture = element['typelecture']+""
        r.Vrm = element['vrm']
        r.Vrms = element['vrms']
        r.Yearmodel = element['yearmodel']
        r.Vin = element['vin']
        r.Hit = element['idhits']
      auxReads.push(r);
    });
    this.alerts = auxReads
  }

}
