import { Component, OnInit, Inject, ViewChild, AfterViewInit } from '@angular/core';
import { ReadsComponent } from '../reads/reads.component';
import { ReadModel } from '../services/models/read.model';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { DOCUMENT } from '@angular/common';
import { fromEvent } from 'rxjs';
import { first } from 'rxjs/operators';

declare var Microsoft: any;

@Component({
  selector: 'app-bing-map-dialog',
  templateUrl: './bing-map-dialog.component.html',
  styleUrls: ['./bing-map-dialog.component.css']
})
///<reference types="@types/bingmaps"/>
export class BingMapDialogComponent implements OnInit,AfterViewInit {
  @ViewChild('trackerMap', {static: false}) trackerMap;
  public searchManager
  public map
  public noImage = "assets/img/no-image.png"
  public name
  bingMapsScriptUrl = 'https://www.bing.com/api/maps/mapcontrol?branch=experimental';
  
  bingMapsApiKey = 'AvxstKYU5F9rLq045exbCtsbk9yomQe4Yp-t1buWx20xrnRa56btre4jvboWVsrU';
  

  constructor(public dialogRef: MatDialogRef<ReadsComponent>,
    @Inject(DOCUMENT) private document: Document,
    @Inject(MAT_DIALOG_DATA) public data: ReadModel,
    ) { }
    
  ngOnInit() {
    console.log(this.data)
    //this.loadMapScenario();
  }

  ngAfterViewInit(){
    this.loadBingMapsScript();
  }

  loadMapScenario(){
    let coord = this.data.Location.split(",")
    let lat = coord[0].replace('(','')
    let lon = coord[1].replace(')','')
     this.map = new Microsoft.Maps.Map(document.getElementById('myMap'),{
      credentials:null,
      center: new Microsoft.Maps.Location(lat,lon),
      mapTypeId: Microsoft.Maps.MapTypeId.birdseye,
      zoom: 18 
    })
     var pushpin = new Microsoft.Maps.Pushpin(new Microsoft.Maps.Location(lat,lon), { title: 'sample text', color: 'red' });
     var layer = new Microsoft.Maps.Layer();
     layer.add([pushpin]);
     this.map.layers.insert(layer);
    this.reverseGeocode(lat,lon);
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  reverseGeocode(lat,lon) {
    //If search manager is not defined, load the search module.
    if (!this.searchManager) {
        //Create an instance of the search manager and call the reverseGeocode function again.
        Microsoft.Maps.loadModule('Microsoft.Maps.Search', ()=> {
          this.searchManager = new Microsoft.Maps.Search.SearchManager(this.map);
            this.reverseGeocode(lat,lon);
        });
    } else {
      var searchRequest = {
        location: this.map.getCenter(),
        callback:  (r) => {
            //Tell the user the name of the result.
            this.name = r.name;
            var pushpin = new Microsoft.Maps.Pushpin(new Microsoft.Maps.Location(lat,lon), { title: r.name, color: 'red' });
            var layer = new Microsoft.Maps.Layer();
            layer.add([pushpin]);
            this.map.layers.insert(layer);
        },
        errorCallback: (e) => {
            //If there is an error, alert the user about it.
            console.log("Unable to reverse geocode location.")
        }
      };

      //Make the reverse geocode request.
      this.searchManager.reverseGeocode(searchRequest);
    }
  }

  loadBingMapsScript() {
    const script = this.document.createElement('script');
    this.document.body.appendChild(script);

    fromEvent(script, 'load').pipe(first()).subscribe(() => {
      setTimeout(() => {
        this.initializeMap();
      }, 500);
    });

     script.src = `${this.bingMapsScriptUrl}&key=${this.bingMapsApiKey}`;
    //script.src = `${this.bingMapsScriptUrl}`;
    
  }

  initializeMap() {
    let coord = this.data.Location.split(",")
    let lat = coord[0].replace('(','')
    let lon = coord[1].replace(')','')
    this.map = new Microsoft.Maps.Map(this.trackerMap.nativeElement, {
      credentials: null,
      center: new Microsoft.Maps.Location(lat,lon),
      mapTypeId: Microsoft.Maps.MapTypeId.birdseye,
      zoom: 18 
    });
    this.reverseGeocode(lat,lon);
  }
}
