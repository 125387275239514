import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UsersComponent } from '../users/users.component';
import { Services } from '../services/web-services';
import { UserModel } from '../services/models/user.model';
import { Md5 } from 'ts-md5/dist/md5';
@Component({
  selector: 'app-user-dialog',
  templateUrl: './user-dialog.component.html',
  styleUrls: ['./user-dialog.component.css']
})
export class UserDialogComponent implements OnInit {
  public usr = new UserModel();
  public disabled = false;
  constructor(public dialogRef: MatDialogRef<UsersComponent>, public _services: Services) { }

  ngOnInit() {
  }

  onNoClick(): void {
    this.disabled = !this.disabled
    let md5 = new Md5();
    let pass = md5.appendStr(this.usr.password).end();
    this.usr.password = pass+"";
    this._services.postAddUser(this.usr).subscribe(res=>{
      this.disabled = !this.disabled
      this.dialogRef.close();
    },error=>{
      console.log(error)
      this.disabled = !this.disabled
    })
  }

}
