import { DOCUMENT } from '@angular/common';
import { Component, OnInit, ViewChild, AfterViewInit, Inject} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { fromEvent } from 'rxjs';
import { first } from 'rxjs/operators';
import { ReadsComponent } from '../reads/reads.component';
declare var Microsoft: any;
@Component({
  selector: 'app-map-dialog',
  templateUrl: './map-dialog.component.html',
  styleUrls: ['./map-dialog.component.css']
})
export class MapDialogComponent implements OnInit, AfterViewInit {
  @ViewChild('trackerMap', {static: false}) trackerMap;
  bingMapsScriptUrl = 'https://www.bing.com/api/maps/mapcontrol?branch=experimental';
  
  bingMapsApiKey = 'AvxstKYU5F9rLq045exbCtsbk9yomQe4Yp-t1buWx20xrnRa56btre4jvboWVsrU';
  public map
  public searchManager
  public name
  constructor(
    public dialogRef: MatDialogRef<ReadsComponent>,
    @Inject(DOCUMENT) private document: Document,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {
  }

  ngAfterViewInit(){
    if(this.data.Typelecture == "1" || this.data.Typelecture == "3")
      this.loadBingMapsScript();
  }

  loadBingMapsScript() {
    const script = this.document.createElement('script');
    this.document.body.appendChild(script);

    fromEvent(script, 'load').pipe(first()).subscribe(() => {
      setTimeout(() => {
        this.initializeMap();
      }, 500);
    });

    script.src = `${this.bingMapsScriptUrl}&key=${this.bingMapsApiKey}`;
  }

  initializeMap() {
    let coord = this.data.Location.split(",")
    let lat = coord[0].replace('(','')
    let lon = coord[1].replace(')','')
    this.map = new Microsoft.Maps.Map(this.trackerMap.nativeElement, {
      credentials: null,
      center: new Microsoft.Maps.Location(lat,lon),
      mapTypeId: Microsoft.Maps.MapTypeId.birdseye,
      zoom: 18 
    });
    this.reverseGeocode(lat,lon);
  }

  reverseGeocode(lat,lon) {
    //If search manager is not defined, load the search module.
    if (!this.searchManager) {
        //Create an instance of the search manager and call the reverseGeocode function again.
        Microsoft.Maps.loadModule('Microsoft.Maps.Search', ()=> {
          this.searchManager = new Microsoft.Maps.Search.SearchManager(this.map);
            this.reverseGeocode(lat,lon);
        });
    } else {
      var searchRequest = {
        location: this.map.getCenter(),
        callback:  (r) => {
            //Tell the user the name of the result.
            this.name = r.name;
            var pushpin = new Microsoft.Maps.Pushpin(new Microsoft.Maps.Location(lat,lon), { title: r.name, color: 'red' });
            var layer = new Microsoft.Maps.Layer();
            layer.add([pushpin]);
            this.map.layers.insert(layer);
        },
        errorCallback: (e) => {
            //If there is an error, alert the user about it.
            console.log("Unable to reverse geocode location.")
        }
      };

      //Make the reverse geocode request. 
      this.searchManager.reverseGeocode(searchRequest);
    }
  }

}
