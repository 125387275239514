import { Injectable } from '@angular/core';

@Injectable()
export class AuthService{
    constructor(){}

    isAuthenticated():boolean{
        const token = sessionStorage.getItem('token');
        let auxtoken = JSON.parse(token);
        if(auxtoken != null && !!auxtoken.username && !!auxtoken.token && auxtoken.username != "" && auxtoken.token != "")
            return true;
        else
            return false;
    }

    logout(){
        sessionStorage.removeItem("token");
    }
}