import { Component, OnInit, ViewChild, Inject, AfterViewInit } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ReadModel } from '../services/models/read.model';
import { ReadTagModel } from '../services/models/readTag.model';
import { Services } from '../services/web-services';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BingMapDialogComponent } from '../bing-map-dialog/bing-map-dialog.component';
import { ReadTagDetailsComponent } from '../read-tag-details/read-tag-details.component';
import { SearchDialogComponent } from '../search-dialog/search-dialog.component';
import { ZoomImageDialogComponent } from '../zoom-image-dialog/zoom-image-dialog.component';
import { TranslateService } from '@ngx-translate/core';
import { throwError } from 'rxjs';
import { ImageDetailsDialogComponent } from '../image-details-dialog/image-details-dialog.component';
import { BlockUI, NgBlockUI } from 'ng-block-ui';


export class RecordsReceived{
  data:string
  records: ReadTagModel[]
}

@Component({
  selector: 'app-reads',
  templateUrl: './reads.component.html',
  styleUrls: ['./reads.component.css']
})
export class ReadsComponent implements OnInit, AfterViewInit {
  @BlockUI() blockUI: NgBlockUI;
  public displayedColumns: string[]= ['Type', 'PlateVin', 'Timestamp', 'Device', 'Patch', 'Overview', 'Alert'];
  public Cols: string[];
  //dataSource: MatTableDataSource<UserData>;
  public dataP2: MatTableDataSource<ReadTagModel>
  public dataP1: MatTableDataSource<ReadModel>
  public readsP2: Array<ReadTagModel> = [];
  public readsP1: Array<ReadModel> = [];
  public auxReadsP2: Array<ReadTagModel> = [];
  
  public auxData:any;
  public mapLoaded = false;
  public total
  public viewed
  public noImage = "assets/img/no-image.png"
  public map
  public firstTime = true
  public type = 4
  public alertType = 0
  public limit = 10
  public page = 0
  public searchByPlate=false
  public iconViewed = "Hide viewed"
  public readsViewed = false
  public auxTimeOut
  public typeUser
  public auxSound
  public redSvg = false
  public blueSvg = true

  public searching = false
  public plateTag = ""

  public filtroStatus = false
  public filtroRepuve = false
  

  public searchData:ReadModel[];

  @ViewChild(MatSort, {static: true}) sort: MatSort;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

  language:any;
  
  constructor(private _services: Services,
    public translate: TranslateService,
    public dialog: MatDialog
    ) { 
      this.typeUser = JSON.parse(sessionStorage.getItem("token"))
      this.displayedColumns = ['Type', 'PlateVin', 'Timestamp', 'Device', 'Patch', 'Overview', 'Camera', 'Alert'];
    }

  ngOnInit() {
    this.language = localStorage.getItem("language")
    this.translate.use(this.language)
    var xbody = document.getElementsByTagName("body")[0];
    xbody.removeAttribute("style")
    if(this.page==0)
      this.firstTime = true
    
    this.getReadsTags()
    this.auxSound = document.getElementById("audio")
  }

  ngAfterViewInit(){
    let elements = document.getElementsByTagName("img");
    this.dataP2.paginator = this.paginator
    // console.log(elements);
  }

  getReadsTags(){
    if(!this.filtroRepuve){
      if(this.type !=5 ){
        this._services.getReadsTags(this.type,this.limit,this.page).subscribe(res=>{
            this.readsP2 =  [];
            this.auxData = res
            this.total = res["total"]
            this.createReadsTags(res["records"]);
        })
        this.auxTimeOut = setTimeout(()=>{
          if(this.page == 0 && this.searching == false)
            this.ngOnInit();
        },6000)
      }else{
        this._services.getHitsFilter(this.limit, this.page).subscribe(res=>{
          this.readsP2 =  [];
            this.auxData = res
            this.total = res["total"]
            this.createReadsTags(res["records"]);
        })
        this.auxTimeOut = setTimeout(()=>{
          if(this.page == 0 && this.searching == false)
            this.ngOnInit();
        },6000)
      }
    }
    else{
      this._services.getReadsTagsFiltered(this.alertType,this.limit,this.page).subscribe(res=>{
        this.readsP2 =  [];
        this.auxData = res
        this.createReadsTags(res["records"]);
      },error=>{
        console.log(error)
      })
      this.auxTimeOut = setTimeout(()=>{
        if(this.page == 0 && this.searching == false)
          this.ngOnInit();
      },2000)  
    }
  }

  assignData(){
    let sameReads = this.compareReads()
    this.total = this.auxData["total"]
    if(!sameReads){
      this.dataP2 = new MatTableDataSource(this.readsP2)
      if(this.firstTime){
        // this.dataP2.paginator = this.paginator
        this.firstTime = false
      }
      this.paginator.length = this.auxData["total"]
      this.total = this.auxData["total"]
      if(this.page == 0)
        // this.dataP2.paginator.pageIndex = 0
      this.auxReadsP2 = this.readsP2
      setTimeout(()=>{
        this.checkAlerts()
      }, 1000)
      this.blockUI.stop();
    }
  }

  createReadsTags(data){
    data.forEach(element => {
      let r = new ReadTagModel;
        r.Alert = element['status']
        r.Brand = element['brand']
        r.Camera = element['camera']
        r.Country = element['country']
        r.IdAlert = element['idalert']
        r.Device = element['scr_name']
        r.Location = element['alocation']
        r.Messages = element['messages']
        r.Model = element['model']
        r.Overview = element['overview']
        r.Patch = element['patch']
        r.Timestamp = element['timestamp']
        r.Typelecture = element['typelecture']+""
        r.Vrm = element['vrm']
        r.Vrms = element['vrms']
        r.Yearmodel = element['yearmodel']
        r.Vin = element['vin']
        r.Hit = element['idhits']
      this.readsP2.push(r);
    });
    //if(!this.searchByPlate)
      this.assignData();
  }

  showMap(row){
    const dialogRef = this.dialog.open(BingMapDialogComponent, {
      width: '80%',
      height: 'auto',
      data: row
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }

  showDetails(row){
    const dialogRef = this.dialog.open(ReadTagDetailsComponent, {
      disableClose: true,
      width: '95%',
      height: 'auto',
      data: [row]
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }

  showMultipleDetails(row){
    this.dialog.closeAll()
    const dialogRef = this.dialog.open(ReadTagDetailsComponent, {
      disableClose: true,
      width: '95%',
      height: 'auto',
      data: row
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }

  pageChanged(event){
    // this.data.data = []
    this.blockUI.start();
    this.limit = event.pageSize;
    this.page = event.pageIndex;
    this.total = event.length;

    if(this.page == 0)
      this.firstTime = true

    if(this.searching){
      this.searchPlateTag(this.page)
    }else{
      this.ngOnInit()
    } 
  }

  setAlertStyle(){
    let rows = document.getElementById("readsTagsTable")
    console.log(rows)
  }

  onContentChange(event){
    console.log(event)
    let rows = document.getElementById("readsTagsTable")
    console.log(rows)
  }

  refreshData(){
    this.blockUI.start();
    this.searchByPlate = false;
    this.searching = false
    this.plateTag = ""

    this.page = 0

    // this.firstTime = true
    this.alertType = 0
    this.type = 4
    this.filtroRepuve = false
    this.ngOnInit();
    // location.reload()
  }

  clikcImage(event){
    //console.log(event)
    // const dialogRef = this.dialog.open(ImageDetailsDialogComponent, {
    //   width: '45%',
    //   height: '85%',
    //   data: event
    // });
    const dialogRef = this.dialog.open(ImageDetailsDialogComponent, {
      disableClose: true,
      width: '45%',
      data: event
    });

    dialogRef.afterClosed().subscribe(result => {
      //console.log(result);
    });
  }

  getReadsViewed(){
    this.readsViewed = !this.readsViewed

    if(this.readsViewed){
      this.iconViewed = "View all"
    }else{
      this.iconViewed = "Hide viewed"
    }

    this.ngOnInit();
  }

  ngOnDestroy(){
    clearTimeout(this.auxTimeOut);
  }

  compareReads(){
    if(this.readsP2.length > 0){
      let same = true
      let BreakError = {}
      try{
        this.readsP2.forEach((read:ReadTagModel, index)=>{
          if(JSON.stringify(read) === JSON.stringify(this.auxReadsP2[index])){
            // console.log("Same")
          }
          else{
            // console.log("False")
            same = false
            throw BreakError
          }
        })
      }catch(error){
        if(error != BreakError)
          throw error
        else 
          console.log()
      }
      return same
    }else{
      return false
    }
  }

  checkAlerts(){
    let auxAlerts = [] 
    this.readsP2.forEach((read:ReadTagModel)=>{
      if(read.Alert === 3 || read.Hit > 0){
        auxAlerts.push(read)
        // if(!this.filtroRepuve)
          // this.showMultipleDetails(auxAlerts)
        document.getElementById("play").click()
        // setTimeout(()=>{
        //   document.getElementById("audio").pause()
        // },1800)
      }
    })
    
    if(auxAlerts.length > 0){
      this.showMultipleDetails(auxAlerts)
      // this.saveAlerts(auxAlerts);
    }
  }
  play(){
    //document.getElementById("play").click()
    this.auxSound.play();
  }

  changeType(event){
    this.blockUI.start();
    this.page = 0
    this.alertType = null
    console.log(event.value)
    this.type = event.value
    this.filtroStatus = true
    this.filtroRepuve = false
    this.firstTime = true
    this.ngOnInit()
  }

  searchPlateTag(auxPage){
    this.searching = true
    if(auxPage != 0 && auxPage != undefined)
      this.page = auxPage
    else
      this.page = 0
    let plate = this.plateTag.trim()
    this._services.getFindEvent(plate,this.limit,this.page).subscribe(res=>{
      this.readsP2 =  [];
      // this.displayedColumns = [];
      // this.displayedColumns = res["columnNames"]
      this.auxData = res
      // this.total = res["total"]
      this.createReadsTags(res["records"]);
    },error=>{
      console.log(error)
    })
  }

  setColumns(cols){

  }

  typeAlertSelected(event){
    console.log(event.value)
    this.type = null
    this.alertType = event.value
    this.firstTime = true
    if(this.alertType != 0 &&  this.alertType != 5){
      this.filtroStatus = false
      this.filtroRepuve = true
    }
    else{
      this.filtroRepuve = false
    }

  }

  saveAlerts(alerts){
    let alertsSaved = JSON.parse(localStorage.getItem("alerts"))
    let alertsToSave = []
    for(let a in alerts){
      if(!(a in alertsSaved)){
        alertsSaved.push(a)
      }
    }
    let json = JSON.stringify(alertsSaved)
    localStorage.setItem("alerts", json)
  } 

}

function compare(a: number | string, b: number | string, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
