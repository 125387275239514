import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Services } from '../services/web-services';
import { MatSnackBar, MAT_SNACK_BAR_DATA} from '@angular/material/snack-bar';
import { SnackBarComponent } from '../snack-bar/snack-bar.component';
import { ReadModel } from '../services/models/read.model';

export class RecordsReceived{
  data:string
  records: ReadModel[]
  total:string
  plate:string
}

@Component({
  selector: 'app-search-dialog',
  templateUrl: './search-dialog.component.html',
  styleUrls: ['./search-dialog.component.css']
})
export class SearchDialogComponent implements OnInit {
  public plate
  public msg = "";
  public reads: ReadModel[] = [];

  constructor(
    public dialogRef: MatDialogRef<SearchDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: RecordsReceived,
    public _services:Services,
    public _snackBar:MatSnackBar
    ) {}

  ngOnInit() {
    // this.data["records"] = this.msg
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  search(){
    if(this.plate == undefined || this.plate == ""){
      this.msg = "Please type the plate to search";
      this.openSnackBar()
    }else{
      this._services.getPlateSearched(this.plate,10,0).subscribe(res=>{
        if(res["records"].length > 0){
          this.createRead(res["records"])
          this.data.records = this.reads;
          this.data.total = res["total"]
          this.data.plate = this.plate
          this.save();
        }else{
          this.msg ="Recods not found";
          this.openSnackBar();
          this.dialogRef.close();
        }
      },error=>{
        console.log(error)
      })
    }
  }

  save(){
    this.dialogRef.close(this.data);
  }

  openSnackBar() {
    this._snackBar.openFromComponent(SnackBarComponent, {
      duration: 5000,
      data: this.msg,
    });
  }

  createRead(data){
    data.forEach(element => {
      let r = new ReadModel;
      r.Camera = element["camera"]
      r.Location = element["location"]
      r.Confidence = element["red_Confidence"]
      r.ID = element["red_ID"]
      r.ManualEntry = element["red_ManualEntry"]
      r.Misread = element["red_Misread"]
      r.NoImages = element["red_NoImages"]
      r.Overview = element["red_Overview"]
      r.Path = element["red_Patch"]
      r.Syntax = element["red_Syntax"]
      r.Timestamp = element["red_TimeStamp"]
      r.Plate = element["red_VRM"]
      r.SourceID = element["red_src_ID"]
      r.UserID = element["usr_LoginName"]
      r.Viewed = element["viewed"]
      r.Device = element["src_Name"]

      this.reads.push(r);
    });
    //this.assignData();
  }

}
