import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-read',
  templateUrl: './read.component.html',
  styleUrls: ['./read.component.css']
})
export class ReadComponent implements OnInit {
  @Input() row;
  constructor() { }

  ngOnInit() {
    console.log(this.row)
  }

}
