import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { SourceModel } from '../services/models/source.model';
import { Services } from '../services/web-services';

/** Constants used to fill up our data base. */
// const COLORS: string[] = [
//   'maroon', 'red', 'orange', 'yellow', 'olive', 'green', 'purple', 'fuchsia', 'lime', 'teal',
//   'aqua', 'blue', 'navy', 'black', 'gray'
// ];
// const NAMES: string[] = [
//   'Maia', 'Asher', 'Olivia', 'Atticus', 'Amelia', 'Jack', 'Charlotte', 'Theodore', 'Isla', 'Oliver',
//   'Isabella', 'Jasper', 'Cora', 'Levi', 'Violet', 'Arthur', 'Mia', 'Thomas', 'Elizabeth'
// ];

@Component({
  selector: 'app-devices',
  templateUrl: './devices.component.html',
  styleUrls: ['./devices.component.css']
})
export class DevicesComponent implements OnInit {
  displayedColumns: string[] ;//= ['select','id', 'name', 'progress', 'color'];
  dataSource: MatTableDataSource<SourceModel>;
  selection = new SelectionModel<SourceModel>(true, []);
  sources: Array<SourceModel> = []

  @ViewChild(MatSort, {static: true}) sort: MatSort;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

  constructor(private _services: Services ) { 
    // const users = Array.from({length: 100}, (_, k) => this.createNewUser(k + 1));
    // this.dataSource = new MatTableDataSource(users);
  }
  ngOnInit() {
    this.displayedColumns = ['select','ID','Name','Description','Serial Number','Settings','Firmware','Deleted'];
    this._services.getSources().subscribe(res=>{
      this.createSource(res['sources']);
    },error=>{
      console.log(error)
    })

    // setTimeout(()=>{
    //   this.dataSource.sort = this.sort;
    //   this.dataSource.paginator = this.paginator;
    // },500)
  }
  assignData(){
    if(this.sources.length>0){
      this.dataSource = new MatTableDataSource(this.sources)
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
    }
    this.sources.forEach(element => {
      if(element.Enabled){
        this.selection.select(element)
      }
    });
   }

  createSource(data){
    data.forEach(element => {
      let s = new SourceModel();
      s.Deleted = element["Deleted"]
      s.Description = element["src_Description"]
      s.Enabled = element["src_Enabled"]
      s.FirmwareVersion = element["src_FirmwareVersion"]
      s.ID = element["src_ID"]
      s.Name = element["src_Name"]
      s.SerialNumber = element["src_SerialNumber"]
      s.SettingsId = element["src_ste_ID"]
      this.sources.push(s);
    });
    this.assignData();
  }

  /*isAllSelected() {
    if(this.dataSource != undefined){
      const numSelected = this.selection.selected.length;
      const numRows = this.dataSource.data.length;
      return numSelected === numRows;
    }
  }

  // Selects all rows if they are not all selected; otherwise clear selection. 
  masterToggle() {
    this.isAllSelected() ? this.selection.clear() : this.dataSource.data.forEach(row => this.selection.select(row));
  }

  // The label for the checkbox on the passed row 
  checkboxLabel(row?: SourceModel): string {
    if(this.dataSource != undefined){
      if(row!= undefined){
        if(row.Enabled){
          this.selection.select(row);
        }
        if (!row) {
          setTimeout(()=>{
            return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
          },500)
        }
        return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.ID + 1}`;
      }
    }
  }*/

  onCheckSource(event,row:SourceModel){
    if(row.Enabled){
      
    }
    
    let auxSelect = this.selection.isSelected(row) ? 'deselect' : 'select';
    if(auxSelect == "select"){
      this.selection.select(row)
      this._services.putActivateSource(row.Name).subscribe(res=>{
      },error=>{
        console.log(error);
      })
    }else{
      this.selection.deselect(row)

      this._services.putDisableSource(row.Name).subscribe(res=>{
      },error=>{
        console.log(error)
      })
    }
    // this._services.putActivateSource(row.Name).subscribe(res=>{
    //   console.log(res)
    // },error=>{
    //   console.log(error);
    // })
  }

}
