export class ReadModel{
    Camera:string
    Confidence:string
    ID:string
    ManualEntry:string
    Misread:string
    Syntax:string
    Timestamp:string
    Plate:string
    SourceID:string
    UserID:string
    Viewed:string
    Location:string
    Path:string
    Overview:string
    NoImages:string
    Device: string
    Speed: string
    Notes: string
    NoPlate: boolean
    noteUsrID: string
    isPaid:boolean
}