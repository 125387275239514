export class ReadTagModel{
    Alert:number
    Brand:string
    Camera:any
    Country:string
    IdAlert:string
    Location:string
    Messages:string
    Model:string
    Overview:string
    Patch:string
    Device:string
    Timestamp:string
    Typelecture:string
    Vrm:string
    Vrms:string
    Yearmodel:string
    Vin:string
    status:any
    Hit:any

}