import { Injectable } from '@angular/core';
import { User } from './login.model';

@Injectable()
export class LoginService{
    private isUserLoggedIn;
    public userLogged:User;

    constructor(){
        this.isUserLoggedIn = false;
    }

    setUserLoggedIn(user:User){
        this.isUserLoggedIn = true;
        this.userLogged = user;
        //localStorage.setItem('currentUser',JSON.stringify(user));
        sessionStorage.setItem('token',JSON.stringify(user));
    }

    getUserLoggedIn(){
        //return JSON.parse(localStorage.getItem('currentUser'));
        return JSON.parse(sessionStorage.getItem('token'));
    }
}