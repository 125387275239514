import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { HttpClientModule } from '@angular/common/http'
import { HttpClientJsonpModule } from '@angular/common/http'
import { UserModel } from './models/user.model';
import { parseTemplate } from '@angular/compiler';
//import { Observable } from 'rxjs/Observable';

@Injectable() export class Services{
    public strURL = "http://pagis-backend-411000079.us-east-1.elb.amazonaws.com:8080";
    //public strURL = "http://localhost:8080";
    public pathURL = "/api/v1";
    public token
    public pathComplete = this.strURL+this.pathURL;
    //auxToken = JSON.parse(sessionStorage.getItem('token')) != null ? JSON.parse(sessionStorage.getItem('token')).token : ""
    
    //public token = this.auxToken != null ? this.auxToken : ""
    
    constructor(private http:HttpClient){
    }

    getTokenSaved():string{
        let tokenObj = JSON.parse(sessionStorage.getItem('token'))
        if(tokenObj != null && tokenObj != undefined){
            let tok = tokenObj.token
            if(tok != undefined && tok != null && tok != ""){
                return "?token=" +tok
            }else{
                return "" 
            }
        }else{
            return ""
        }
    }
    

    login(credentials){  //'N30l0gy:N30l0gy1234'
        var headers = new HttpHeaders();
        headers = headers.append("Content-Type","application/json");
        let auxdate = new Date().toISOString().split("T")[0] + " "+new Date().toLocaleTimeString()
        sessionStorage.setItem("Datetime", auxdate)
        return this.http.post(this.pathComplete+'/users/login',credentials,{headers});
    }

    getReads(){
        let token = this.getTokenSaved();
        if(token != ""){
            var headers = new HttpHeaders();
            headers = headers.append("Content-Type","application/json");
            return this.http.get(this.pathComplete+"/sources/all/reads"+token,{headers});
        }
    }
    getReadsByPage(limit,offset){
        let token = this.getTokenSaved();
        if(token != ""){
            var headers = new HttpHeaders();
            headers = headers.append("Content-Type","application/json");
            return this.http.get(this.pathComplete+"/sources/all/reads/"+limit+"/"+offset+token,{headers});
        }
    }

    getSources(){
        let token = this.getTokenSaved();
        if(token != ""){
            var headers = new HttpHeaders();
            headers = headers.append("Content-Type","application/json");
            return this.http.get(this.pathComplete+"/sources"+token,{headers});
        }
    }

    putActivateSource(sourceName){
        let token = this.getTokenSaved();
        if(token != ""){
            var headers = new HttpHeaders();
            headers = headers.append("Content-Type","application/json");
            return this.http.put(this.pathComplete+"/sources/"+ sourceName +"/activate"+token,{headers});
        }
    }

    putDisableSource(sourceName){
        let token = this.getTokenSaved();
        if(token != ""){
            var headers = new HttpHeaders();
            headers = headers.append("Content-Type","application/json");
            return this.http.put(this.pathComplete+"/sources/"+ sourceName +"/disable"+token,{headers});
        }
    }

    getAllUsers(){
        let token = this.getTokenSaved();
        if(token != ""){
            var headers = new HttpHeaders();
            headers = headers.append("Content-Type","application/json");
            return this.http.get(this.pathComplete+"/users"+token,{headers});        
        }
    }

    postAddUser(data:UserModel){
        let token = this.getTokenSaved();
        if(token != ""){
            var headers = new HttpHeaders();
            headers = headers.append("Content-Type","application/json");
            return this.http.post(this.pathComplete+'/users'+token,data,{headers});
        }
    }

    putActivateUser(loginName){
        let token = this.getTokenSaved();
        if(token != ""){
            var headers = new HttpHeaders();
            headers = headers.append("Content-Type","application/json");
            return this.http.put(this.pathComplete+"/users/"+ loginName +"/activate"+token,{headers});
        }
    }

    putDisableUser(loginName){
        let token = this.getTokenSaved();
        if(token != ""){
            var headers = new HttpHeaders();
            headers = headers.append("Content-Type","application/json");
            return this.http.put(this.pathComplete+"/users/"+ loginName +"/disable"+token,{headers});
        }
    }

    getPlateSearched(plate,limit,offset){
        let token = this.getTokenSaved();
        if(token != ""){
            var headers = new HttpHeaders();
            headers = headers.append("Content-Type","application/json");
            return this.http.get(this.pathComplete+"/sources/all/reads/"+plate+"/"+limit+"/"+offset+token,{headers});        
        }
    }

    editPlate(readID,plate){///api/v1/sources/all/reads/{read_id}
        let token = this.getTokenSaved();
        if(token != ""){
            var headers = new HttpHeaders();
            headers = headers.append("Content-Type","application/json");
            return this.http.put(this.pathComplete+"/sources/all/reads/"+ readID +token, plate,{headers});//sources/all/reads/<int:read_id>/noplate
        }
    }

    getReadsTags(type,limit,offset){
        let token = this.getTokenSaved();
        if(token != ""){
            var headers = new HttpHeaders();
            headers = headers.append("Content-Type","application/json");
            return this.http.get(this.pathComplete+"/sources/all/readstags/"+type+"/"+limit+"/"+offset+token,{headers});
            
        }
    }

    getReadsTagsFiltered(type,limit,offset){
        let token = this.getTokenSaved();
        if(token != ""){
            var headers = new HttpHeaders();
            headers = headers.append("Content-Type","application/json");
            return this.http.get(this.pathComplete+"/sources/all/readstagsFilter/"+type+"/"+limit+"/"+offset+token,{headers});
        }
    }

    getCarfaxAlert(alertId){
        let token = this.getTokenSaved();
        if(token != ""){
            var headers = new HttpHeaders();
            headers = headers.append("Content-Type","application/json");
            return this.http.get(this.pathComplete+"/sources/all/carfaxAlerts/"+alertId+token,{headers});
        }
    }

    getFindEvent(plateTag, limit, offset){
        let token = this.getTokenSaved();
        if(token != ""){
            var headers = new HttpHeaders();
            headers = headers.append("Content-Type","application/json");
            return this.http.get(this.pathComplete+"/sources/all/findEvent/"+plateTag+"/"+limit+"/"+offset+token,{headers});
        }
    }

    getHit(id){
        let token = this.getTokenSaved();
        if(token != ""){
            var headers = new HttpHeaders();
            headers = headers.append("Content-Type","application/json");
            return this.http.get(this.pathComplete+"/sources/all/getHit/"+id+token,{headers});
        }
    }

    getHitsFilter(limit,offset){
        let token = this.getTokenSaved();
        if(token != ""){
            var headers = new HttpHeaders();
            headers = headers.append("Content-Type","application/json");
            return this.http.get(this.pathComplete+"/sources/all/readsHitsFilter/"+limit+"/"+offset+token,{headers});
        }
    }

    getCountAlertsSession(){
        let token = this.getTokenSaved();
        if(token != ""){
            var headers = new HttpHeaders();
            headers = headers.append("Content-Type","application/json");
            // let auxdate = new Date().toISOString().split("T")[0] + " "+new Date().toLocaleTimeString()
            let auxdate = sessionStorage.getItem("Datetime")
            // let auxdate = "2023-03-28 00:57:06.498596-06"
            return this.http.get(this.pathComplete+"/sources/all/getAlertsSession/"+auxdate+token,{headers});
        }
    }

}

