import { Component, OnInit, Inject, ViewChild, AfterViewInit } from '@angular/core';
import { ReadsComponent } from '../reads/reads.component';
import { ReadModel } from '../services/models/read.model';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { DOCUMENT } from '@angular/common';
import { fromEvent } from 'rxjs';
import { first } from 'rxjs/operators';
import { ReadTagModel } from '../services/models/readTag.model';
import { Services } from '../services/web-services';
import { ZoomImageDialogComponent } from '../zoom-image-dialog/zoom-image-dialog.component';
import { ImageDetailsDialogComponent } from '../image-details-dialog/image-details-dialog.component';
import { MapDialogComponent } from '../map-dialog/map-dialog.component';
import { HeaderComponent } from '../header/header.component';

declare var Microsoft: any;

@Component({
  selector: 'app-read-tag-details',
  templateUrl: './read-tag-details.component.html',
  styleUrls: ['./read-tag-details.component.css']
})
///<reference types="@types/bingmaps"/>
export class ReadTagDetailsComponent implements OnInit,AfterViewInit {

  @ViewChild('trackerMap', {static: false}) trackerMap;
  public searchManager
  public map
  public noImage = "assets/img/no-image.png"
  public name
  public alerts= []
  public data
  public i = 0
  bingMapsScriptUrl = 'https://www.bing.com/api/maps/mapcontrol?branch=experimental';
  
  bingMapsApiKey = 'AvxstKYU5F9rLq045exbCtsbk9yomQe4Yp-t1buWx20xrnRa56btre4jvboWVsrU';
  
  public hotlists = ""
  public info = ""
  public initial = 1
  public final
  public lat
  public lon

  constructor(
    public zoomDialog: MatDialog,
    public dialogRef: MatDialogRef<ReadsComponent, HeaderComponent>,
    @Inject(DOCUMENT) private document: Document,
    // @Inject(MAT_DIALOG_DATA) public data: ReadTagModel,
    @Inject(MAT_DIALOG_DATA) public dataArray: ReadTagModel[],
    private _services: Services,
    public dialog: MatDialog
  ) { }

  ngOnInit() {
    this.data = this.dataArray[this.i]
    let coord = this.data.Location.split(",")
    this.lat = coord[0].replace('(','')
    this.lon = coord[1].replace(')','')
    this._services.getCarfaxAlert(this.data.IdAlert).subscribe(res=>{
      console.log(res)
      this.alerts = res['records']
    })

    if(this.data.Hit > 0)
      this._services.getHit(this.data.Hit).subscribe(res=>{
        // let hit = res['records'][0]
        // this.hotlist = hit['hit_HotList']
        // this.info = hit['hit_Information']
        this.hotlists = res['records']
      })
  }

  ngAfterViewInit(){
    // if(this.data.Typelecture == "1" || this.data.Typelecture == "3")
    //   this.loadBingMapsScript();
  }

  loadBingMapsScript() {
    const script = this.document.createElement('script');
    this.document.body.appendChild(script);

    fromEvent(script, 'load').pipe(first()).subscribe(() => {
      setTimeout(() => {
        this.initializeMap();
      }, 500);
    });

    script.src = `${this.bingMapsScriptUrl}&key=${this.bingMapsApiKey}`;
    //script.src = `${this.bingMapsScriptUrl}`;
    
  }

  initializeMap() {
    let coord = this.data.Location.split(",")
    this.lat = coord[0].replace('(','')
    this.lon = coord[1].replace(')','')
    this.map = new Microsoft.Maps.Map(this.trackerMap.nativeElement, {
      credentials: null,
      center: new Microsoft.Maps.Location(this.lat,this.lon),
      mapTypeId: Microsoft.Maps.MapTypeId.birdseye,
      zoom: 18 
    });
    this.reverseGeocode(this.lat,this.lon);
  }

  reverseGeocode(lat,lon) {
    //If search manager is not defined, load the search module.
    if (!this.searchManager) {
        //Create an instance of the search manager and call the reverseGeocode function again.
        Microsoft.Maps.loadModule('Microsoft.Maps.Search', ()=> {
          this.searchManager = new Microsoft.Maps.Search.SearchManager(this.map);
            this.reverseGeocode(lat,lon);
        });
    } else {
      var searchRequest = {
        location: this.map.getCenter(),
        callback:  (r) => {
            //Tell the user the name of the result.
            this.name = r.name;
            var pushpin = new Microsoft.Maps.Pushpin(new Microsoft.Maps.Location(lat,lon), { title: r.name, color: 'red' });
            var layer = new Microsoft.Maps.Layer();
            layer.add([pushpin]);
            this.map.layers.insert(layer);
        },
        errorCallback: (e) => {
            //If there is an error, alert the user about it.
            console.log("Unable to reverse geocode location.")
        }
      };

      //Make the reverse geocode request. 
      this.searchManager.reverseGeocode(searchRequest);
    }
  }

  loadMapScenario(){
    let coord = this.data.Location.split(",")
    let lat = coord[0].replace('(','')
    let lon = coord[1].replace(')','')
     this.map = new Microsoft.Maps.Map(document.getElementById('myMap'),{
      credentials:null,
      center: new Microsoft.Maps.Location(lat,lon),
      mapTypeId: Microsoft.Maps.MapTypeId.birdseye,
      zoom: 18 
    })
     var pushpin = new Microsoft.Maps.Pushpin(new Microsoft.Maps.Location(lat,lon), { title: 'sample text', color: 'red' });
     var layer = new Microsoft.Maps.Layer();
     layer.add([pushpin]);
     this.map.layers.insert(layer);
    this.reverseGeocode(lat,lon);
  }

  zoomImageDialog(image){
    const dialogRef = this.zoomDialog.open(ImageDetailsDialogComponent, {
      disableClose: true,
      width: '45%',
      data: image
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }

  next(){
    this.alerts = []
    this.i = this.i + 1;
    if(this.i < this.dataArray.length)
      this.data = this.dataArray[this.i]
    else
      this.i = this.dataArray.length-1;
    // this.loadBingMapsScript()
    // this.removePushpin()
    // this.insertPushpin()
    if(this.data.IdAlert)
      this._services.getCarfaxAlert(this.data.IdAlert).subscribe(res=>{
        console.log(res)
        this.alerts = res['records']
      })
  }

  prev(){
    this.alerts = []
    this.i = this.i - 1;
    if(this.i >= 0)
      this.data = this.dataArray[this.i]
    else
      this.i = 0;
    // this.loadBingMapsScript()
    // this.removePushpin()
    // this.insertPushpin()
    if(this.data.IdAlert)
      this._services.getCarfaxAlert(this.data.IdAlert).subscribe(res=>{
        console.log(res)
        this.alerts = res['records']
      })
  }

  removePushpin(){
    for (var i = this.map.layers.length - 1; i >= 0; i--) {
      var pushpin = this.map.layers[i]
      this.map.layers.removeAt(i);
    }
  }

  insertPushpin(){
    let coord = this.data.Location.split(",")
    let lat = coord[0].replace('(','')
    let lon = coord[1].replace(')','')
    var pushpin = new Microsoft.Maps.Pushpin(new Microsoft.Maps.Location(lat,lon), { title: 'sample text', color: 'red' });
    var layer = new Microsoft.Maps.Layer();
    layer.add([pushpin]);
    this.map.layers.insert(layer);
    this.map.setView({
      center: new Microsoft.Maps.Location(lat, lon)
    })

    this.reverseGeocode(lat,lon);
  }

  showMap(data){
    const dialogRef = this.dialog.open(MapDialogComponent, {
      disableClose: true,
      width: '95%',
      height: '80%',
      data: data
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }

}
