import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { User } from './login.model';
import { LoginService } from './login.service'
import { Services } from '../services/web-services';
import { Md5 } from 'ts-md5/dist/md5';
import { SnackBarComponent } from '../snack-bar/snack-bar.component';
import { MatSnackBar, MAT_SNACK_BAR_DATA} from '@angular/material/snack-bar';
import { FormControl, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  
  public username;
  public password;
  public msg = "";
  public language;
  public langs;

  constructor(private router: Router,
    private loginService:LoginService,
    public _service : Services,
    public _snackBar:MatSnackBar,
    public translate: TranslateService
    ) { }

  ngOnInit() {
    this.language = localStorage.getItem("language");
    this.langs = this.translate.getLangs();
    if(this.language === null)
      this.language = "es"
      localStorage.setItem("language","es")
    if(this.language === "en"){
      this.langs = this.langs.reverse();
    }
    let body = document.getElementsByTagName("body")[0];
    
  }

  login(){
    if(this.username != "" && this.password != ""){
      let md5 = new Md5();
      let credentials = {
        "loginName": "",
        "password":""
      }
      let usrName = this.username
      let pass = md5.appendStr(this.password).end();
      credentials.loginName = usrName+"";
      credentials.password = pass+""; 
      this._service.login(credentials).subscribe(res=>{
        if(!!res["token"] && res["token"] != ""){
          let DateToSave = new Date()
          let d = DateToSave.getMinutes() + 180;
          DateToSave.setMinutes(d);
          let u:User = { username: this.username, token: res["token"], date: DateToSave.getTime(), usrID: res["userID"], usrProyect: res['usr_Proyect']}
          this.loginService.setUserLoggedIn(u);
          setTimeout(()=>{
            this.router.navigate(["reads"]);
          },500)
        }else{
          this.msg = res["status"]
          this.openSnackBar();
        }
      },error=>{
        console.log(error)
        if(!!error.status){
          this.msg = error.status
          this.openSnackBar();
        }else{
          this.msg = error.message
          this.openSnackBar();
        }
      })
    }
  }

  openSnackBar() {
    this._snackBar.openFromComponent(SnackBarComponent, {
      duration: 5000,
      data: this.msg,
    });
  }

  translateLanguageTo(lang) {
    console.log(lang)
    this.translate.use(lang);
    localStorage.setItem("language",lang)
  }

}
