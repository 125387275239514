export class UserModel{
    Deleted: string
    createdAt: string
    description: string
    enabled: string
    expiration: string
    ID: string
    loginName: string
    name: string
    password: string
    passwordChanged: string
}