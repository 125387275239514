import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FormsModule } from '@angular/forms';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatMenuModule } from '@angular/material/menu';
import { MatTableModule } from '@angular/material/table';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule, MatRippleModule } from '@angular/material/core';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSelectModule } from '@angular/material';
import { MatBadgeModule } from '@angular/material';
import { BlockUIModule } from 'ng-block-ui';

import { LoginComponent } from './login/login.component';
import { HeaderComponent } from './header/header.component';
import { ReadsComponent } from './reads/reads.component';
import { DevicesComponent } from './devices/devices.component';
import { UsersComponent } from './users/users.component';

import { AuthService } from './guards/auth.service';
import { LoginService } from './login/login.service';
import { Services } from './services/web-services';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { UserDialogComponent } from './user-dialog/user-dialog.component';
import { BingMapDialogComponent } from './bing-map-dialog/bing-map-dialog.component';
import { SearchDialogComponent } from './search-dialog/search-dialog.component';
import { SnackBarComponent } from './snack-bar/snack-bar.component';
import { ZoomImageDialogComponent } from './zoom-image-dialog/zoom-image-dialog.component';
import { ZoomComponent } from './zoom-image-dialog/zoom.component';
import { ReadComponent } from './readTag/read.component';
import { TagComponent } from './tag/tag.component';
import { ReadTagDetailsComponent } from './read-tag-details/read-tag-details.component';
import { AlertComponent } from './alert/alert.component';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { ImageDetailsDialogComponent } from './image-details-dialog/image-details-dialog.component';
import { MapDialogComponent } from './map-dialog/map-dialog.component';


export function httpTranslateLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    HeaderComponent,
    ReadsComponent,
    DevicesComponent,
    UsersComponent,
    UserDialogComponent,
    BingMapDialogComponent,
    SearchDialogComponent,
    SnackBarComponent,
    ZoomImageDialogComponent,
    ZoomComponent,
    ReadComponent,
    TagComponent,
    ReadTagDetailsComponent,
    AlertComponent,
    ImageDetailsDialogComponent,
    MapDialogComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    MatButtonModule,
    MatInputModule,
    MatToolbarModule,
    MatCardModule,
    MatIconModule,
    MatSidenavModule,
    MatFormFieldModule,
    MatProgressSpinnerModule,
    MatMenuModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatCheckboxModule,
    MatDialogModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatRippleModule,
    MatSnackBarModule,
    BlockUIModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: httpTranslateLoaderFactory,
        deps: [HttpClient]
      }
    }),
    MatSelectModule,
    MatBadgeModule
  ],
  providers: [AuthService,
    LoginService,
    Services,
    ZoomImageDialogComponent
  ],
  bootstrap: [AppComponent],
  entryComponents: [ UserDialogComponent,BingMapDialogComponent,SearchDialogComponent,SnackBarComponent, ZoomImageDialogComponent, ReadTagDetailsComponent, ImageDetailsDialogComponent, MapDialogComponent ]
})
export class AppModule { }
